import * as TYPES from "./actionTypes";

const defaultState = {
  loads: []
};

export default function app(state = defaultState, action) {
  switch (action.type) {
    case TYPES.LOADS:
      return {
        ...state,
        loads: action.value
      };
    default:
      return state
  }
}
